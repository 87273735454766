import Link from 'next/link';
import PublicLayout from '../components/layouts/PublicLayout';
import { Container} from 'reactstrap';
import { useState, useEffect } from "react";
import axios from 'axios';
import Head from 'next/head';
import { useRouter } from 'next/router';

function Page404() {
	const router = useRouter();
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		getRequestApi();
	}, []);

	const getRequestApi = () => {

		axios.get(process.env.NEXT_PUBLIC_API_URL+'poupler_categories?is_popular=5&limit=4', { 
			headers: { "Accept": "application/json" }

		}).then(res => {
			setCategories(res.data.data);

		},(error) => {
		});
	}

	const DataTable = () => {
		if(categories.length) {
			return categories.map((res, i) => {
				return (
					<>
					<li key={i}>
						<Link href={{pathname: "/agencies/"+res.attributes.slug}}>
							<a>{res.attributes.name}</a>
						</Link>
					</li>
					</>
				)
			})
		}
	}

	return (
		<>
			<Head>
				<meta name="robots" content="noindex, nofollow" />
				<link rel="canonical" href={process.env.NEXT_PUBLIC_APP_URL + router.asPath} />
			</Head>
			<div className="error-page">
				<div id="background-wrap">
					<div class="bubble x1"></div>
					<div class="bubble x2"></div>
					<div class="bubble x3"></div>
					<div class="bubble x4"></div>
					<div class="bubble x5"></div>
					<div class="bubble x6"></div>
					<div class="bubble x7"></div>
					<div class="bubble x8"></div>
					<div class="bubble x9"></div>
					<div class="bubble x10"></div>
				</div>
				<Container fluid="xl">
					<div className="text-center padding-wrap">
					<div class="error-container">
					<span>4</span>
					<span className="o_text"><span class="screen-reader-text">0</span></span>
					<span>4</span>
					</div>
					<h4>Page Not Found</h4>
					<Link href={"/"} ><a className="primary-btn"><span>Go to Home</span> </a></Link>
					</div>
					<div className="bottom-spacing_margin">
	                    <ul className="cat_listing">
							{DataTable()}
						</ul>
					</div>
				</Container>
			</div>
		</>
	);
}

Page404.Layout = PublicLayout;

export default Page404;
